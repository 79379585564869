import React from 'react';

export interface ItemProps {
  image: string;
  title: string;
  describe: string;
}

export default function Item({ image, title, describe }: ItemProps) {
  return (
    <li className="flex gap-12 md:gap-[60px] items-center lg:flex-row flex-col lg:even:flex-row-reverse">
      <div className="md:w-1/2">
        <img className="w-full" src={image} alt={title} />
      </div>
      <div className="flex flex-col gap-4 md:gap-5 md:w-1/2">
        <h4 className="font-bold text-13xl md:text-16xl linear-text bg-linear-blue">{title}</h4>
        <p className="text-black text-opacity-80 text-4xl md:text-6xl">{describe}</p>
      </div>
    </li>
  );
}
