import React from 'react';
import { Link } from 'react-router-dom';

export interface ItemProps {
  image: string;
  describe: string;
}

export default function Item({ image, describe }: ItemProps) {
  return (
    <li className="flex flex-col md:items-center md:text-center">
      <img className="w-[38px] mb-8" src={image} alt="" />
      <p className="text-black text-6xl mb-5 md:mb-[52px]">{describe}</p>
      <Link
        to="/contact-us"
        className="flex gap-1 text-md text-primary font-aton items-center uppercase"
      >
        <span>Contact us for know more</span>
        <img className="w-4 h-4" src="/images/chevron-right.svg" alt="" />
      </Link>
    </li>
  );
}
