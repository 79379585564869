import React from 'react';
import { Link } from 'react-router-dom';
import Pages from '../navbar/Pages';
import Copyright from './Copyright';

interface FooterProps {
  toggleSub: () => void;
}

export default function Footer({ toggleSub }: FooterProps) {
  return (
    <footer className="container border-t border-gray-200 pb-10 md:pb-[70px] text-center md:text-left">
      <div className="flex md:flex-row flex-col md:justify-between pt-10 md:pt-[60px] pb-6 md:pb-[22px] md:items-center">
        <div className="w-[162px] mb-7 mx-auto md:mx-0">
          <Link to="/">
            <img src="/images/logo (1) 1.jpg" className="w-[162px]" alt="" />
          </Link>
        </div>
        <div className="hidden md:block">
          <Pages black />
        </div>
        <div className="flex flex-col">
          <button onClick={toggleSub} type="button" className="black-button w-full text-center">
            SCHEDULE A CALL
          </button>
        </div>
      </div>
      <Copyright />
    </footer>
  );
}
