import React from 'react';
import SectionTitle from '../../../components/global/SectionTitle';
import Item from './Item';
import lists from './lists.json';

interface OurServiesProps {
  toggleSub: () => void;
}

export default function OurServies({ toggleSub }: OurServiesProps) {
  return (
    <section className="md:pt-[136px] pt-24 bg-center pb-20 md:pb-[150px] overflow-hidden">
      <SectionTitle
        color="blue"
        title="Our services"
        subtitle="Here’s a quick overview of the amazing services we have to offer you"
        end
        lastChildren
      >
        <img
          className="w-[126px] translate-x-28 -translate-y-20 md:translate-y-10 md:-translate-x-48"
          src="/images/Group 14412 (1).svg"
          alt=""
        />
      </SectionTitle>

      <div className="container">
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 list-none gap-10 mb-20 md:mb-36">
          {lists.map(({ id, ...rest }) => (
            <Item key={id} {...rest} />
          ))}
          <li className="flex flex-col gap-4 text-center items-center">
            <img src="/images/Group 14579.svg" className="w-14 mx-auto block" alt="" />
            <b className="text-8xl text-black">Get started now!</b>
            <button
              type="button"
              onClick={toggleSub}
              className="primary-button text-center w-full md:max-w-[190px]"
            >
              SCHEDULE A CALL
            </button>
          </li>
        </ul>

        <div className="relative rounded-3xl overflow-hidden">
          <img
            src="/images/Frame 2387 (1).svg"
            className="absolute left-0 right-0 top-0 bottom-0 hidden md:block h-full"
            alt=""
          />
          <img
            src="/images/Frame 2389.svg"
            className="absolute left-0 right-0 top-0 bottom-0 block md:hidden w-full"
            alt=""
          />
          <div className="flex md:flex-row flex-col justify-between items-center py-12 md:py-24 relative z-10 max-w-[80%] mx-auto text-center md:text-left gap-8 md:gap-0">
            <div className="text-12xl md:text-15xl text-white lg:w-[50%] md:max-w-[376px]">
              <span>Take your amazon sales&nbsp;</span>
              <span className="font-bold">on the next level!</span>
            </div>
            <div>
              <div className="md:w-[1px] w-10 h-[1px] md:h-10 bg-white mx-10" />
            </div>
            <div className="md:w-[55%] md:max-w-[317px]">
              <p className="text-6xl text-white">
                Let our team of professional <b className="font-bold">Amazon Listing Experts</b>{' '}
                take care of all the hard work for you!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
