import React from 'react';

export interface ItemProps {
  image: string;
  title: string;
  describe: string;
}

export default function Item({ image, title, describe }: ItemProps) {
  return (
    <li className="flex flex-col items-start text-left">
      <div className="h-[66px] mb-5 flex items-end justify-center">
        <img className="" src={image} alt={title} />
      </div>
      <h4 className="font-bold text-8xl mb-3.5 text-black">{title}</h4>
      <p className="text-black text-opacity-80 text-2xl">{describe}</p>
    </li>
  );
}
