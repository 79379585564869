import React from 'react';
import SectionTitle from '../../../components/global/SectionTitle';
import Item from './Item';
import lists from './lists.json';

export default function MeetListIt() {
  return (
    <section className="py-[86px] md:py-[150px]">
      <SectionTitle
        color="primary"
        title="Meet List It"
        subtitle="Your #1 Secret Weapon to amazon Success!"
        direction="flex-row"
        lastChildren
      >
        <img
          className="w-20 h-20 -translate-x-5 -translate-y-3 md:translate-x-6 md:translate-y-1"
          src="/images/Group 14618.svg"
          alt=""
        />
      </SectionTitle>
      <div className="container">
        <ul className="flex list-none flex-col gap-10 md:gap-20">
          {lists.map(({ id, ...rest }) => (
            <Item key={id} {...rest} />
          ))}
        </ul>
      </div>
    </section>
  );
}
