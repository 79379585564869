import React from 'react';

export default function Copyright({ br }: { br?: boolean }) {
  const currentDate = new Date().getFullYear();

  return (
    <p className="md:text-center text-black text-opacity-80 text-xs md:text-3xl">
      Copyright &copy; {currentDate} List IT, LLC. {br && <br className="block md:hidden" />}
      All rights reserved.
    </p>
  );
}
