interface ContactSectionProps {
	toggleSub: () => void;
}

export default function ContactSection({ toggleSub }: ContactSectionProps) {
	return (
		<div className="container">
			<div className="flex lg:flex-row flex-col lg:rounded-[26px] rounded-[22px_22px_99px_99px] bg-black items-center my-[86px] md:my-[150px]">
				<div className="text-white px-8 lg:px-[100px] lg:py-5 flex-grow py-[52px] flex lg:block flex-col lg:text-left text-center">
					<h3 className="font-bold text-14xl lg:text-16xl mb-5 lg:mb-3.5">
						Let’s work together.
					</h3>
					<p className="lg:mb-12 mb-7 text-6xl text-white text-opacity-80">
						Let our team of professional Amazon Listing Experts take care of all
						the hard work for you!
					</p>
					<button onClick={toggleSub} type="button" className="primary-button">
						SCHEDULE A CALL
					</button>
				</div>
				<div className="w-full lg:w-auto">
					<div className="lg:w-[471px] w-full">
						<img
							className="w-full hidden lg:block"
							src="/images/Video.svg"
							alt=""
						/>
						<img
							className="w-full blcok lg:hidden"
							src="/images/Video (2).svg"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
