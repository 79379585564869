interface HeroProps {
	toggleSub: () => void;
}

export default function Hero({ toggleSub }: HeroProps) {
	return (
		<div className="bg-header-dotted bg-repeat bg-center py-[52px] md:py-[126px]">
			<div className="md:grid md:grid-cols-2 flex flex-col-reverse container gap-10 items-center">
				<div>
					<h2 className="text-17xl md:text-19xl font-bold linear-text bg-linear-blue mb-5 md:mb-7">
						Everything you need to list on&nbsp;
						<img
							src="/images/Vector.svg"
							alt=""
							className="w-[144px] md:w-[219px] inline-block align-[-webkit-baseline-middle]"
						/>
					</h2>
					<p className="text-4xl md:text-6xl text-black text-opacity-80 mb-6 md:mb-8">
						List It is a team of friendly and experienced amazon listing
						professionals who know exactly what it takes to become a successful
						seller on Amazon.
					</p>
					<div>
						<button
							className="black-button inline-block w-full md:w-auto text-center md:text-left"
							type="button"
							onClick={toggleSub}
						>
							SCHEDULE A CALL
						</button>
					</div>
				</div>
				<div>
					<img
						className="md:max-w-[598px] w-full"
						src="/images/OBJECTS.svg"
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}
