import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../../components/global/SectionTitle';
import Item from './Item';
import lists from './lists.json';
import Quote from './Quote';

export default function OurServies() {
  return (
    <section className="pt-[70px] md:pt-[140px] bg-center bg-cover bg-no-repeat bg-linear-servies mb-24 overflow-hidden">
      <SectionTitle
        color="blue"
        title="Our services"
        subtitle="Here’s a quick overview of the amazing services we have to offer you"
        lastChildren
        end
      >
        <img
          className="w-[126px] translate-x-28 -translate-y-20 md:translate-y-10 md:-translate-x-48"
          src="/images/Group 14412 (1).svg"
          alt=""
        />
      </SectionTitle>

      <div className="container">
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 list-none gap-10">
          {lists.map(({ id, ...rest }) => (
            <Item key={id} {...rest} />
          ))}
        </ul>
      </div>
      <div className="flex justify-center mt-14 md:mt-4 mb-9 md:mb-8">
        <img src="/images/Arrow_06.svg" alt="" />
      </div>
      <div className="flex justify-center container text-center text-black mb-24">
        <div className="w-full md:max-w-[465px]">
          <h5 className="text-black text-center font-bold text-9xl mb-6">
            If you are interested in all services and want to increase sales on Amazon
          </h5>
          <Link to="/" className="primary-button w-full md:w-auto inline-block">
            Learn more about our Services
          </Link>
        </div>
      </div>
      <Quote />
    </section>
  );
}
