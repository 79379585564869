interface HeaderProps {
	toggleSub: () => void;
}

export default function Header({ toggleSub }: HeaderProps) {
	return (
		<main className="bg-header-dotted bg-repeat bg-center relative z-10">
			<div className="flex lg:flex-row flex-col-reverse container gap-8 items-center pt-10">
				<div className="flex-grow mb-16 lg:mb-0">
					<h2 className="text-17xl lg:text-19xl font-bold linear-text bg-linear-blue mb-4">
						Are you ready to Skyrocket the success and profits of your own&nbsp;
						<br className="hidden lg:block" />
						<img
							src="/images/Vector.svg"
							alt=""
							className="lg:w-[219px] w-[125px] inline-block align-[-webkit-baseline-middle]"
						/>{" "}
						<br className="block lg:hidden" />
						products
						<img
							src="/images/Arrow_24.svg"
							alt=""
							className="lg:w-[66px] h-[74px] lg:h-[121px] inline-block -mt-8 translate-y-14 translate-x-6"
						/>
					</h2>
				</div>
				<div className="lg:max-w-[541px] w-full">
					<img
						className="w-full lg:-mb-28"
						src="/images/Group 48095933.svg"
						alt=""
					/>
				</div>
			</div>
			<div className="bg-subtract xl:mt-0 lg:mt-16">
				<div className="relative container pt-8 lg:pt-[60px] pb-11 lg:pb-[76px]">
					<div className="lg:grid lg:grid-cols-2">
						<h2 className="text-17xl lg:text-19xl font-bold text-white">
							<span>with less time, work and effort?</span>
							<img
								src="/images/Arrow_04.svg"
								className="px-8 hidden lg:inline-block"
								alt=""
							/>
							<div className="inline-block ml-5 lg:ml-0">
								<button
									onClick={toggleSub}
									type="button"
									className="black-button"
								>
									schedule a call
								</button>
							</div>
						</h2>
					</div>
				</div>
			</div>
		</main>
	);
}
