import React from 'react';
import { Link } from 'react-router-dom';

export default function Logo({ logo }: { logo?: string }) {
  return (
    <div className="">
      <Link to="/">
        <img className="w-[117px]" src={logo || '/images/Profile - Linkdin-01 1.jpg'} alt="" />
      </Link>
    </div>
  );
}
