import React, { useState } from 'react';
import Navbar from '../../components/layout/navbar/Navbar';
import useScrollReset from '../../hooks/useScrollReset';
import ContactSection from '../../components/global/ContactSection';
import Footer from '../../components/layout/footer/Footer';
import Hero from './Hero';
import OurServies from './our-servies/OurServies';
import Reviews from './reviews/Reviews';
import PopupSubscribe from '../../components/global/PopupSubscribe';

export default function Services() {
  useScrollReset();
  const [sub, setSub] = useState<boolean>(false);
  const toggleSub = () => setSub((v) => !v);

  return (
    <div>
      <Navbar toggleSub={toggleSub} />
      <Hero toggleSub={toggleSub} />
      <OurServies toggleSub={toggleSub} />
      <Reviews />
      <ContactSection toggleSub={toggleSub} />
      <Footer toggleSub={toggleSub} />

      {sub && <PopupSubscribe closeButton={{ onClick: toggleSub }} />}
    </div>
  );
}
