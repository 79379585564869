import React from 'react';

export default function Rating({
  rating,
  size,
}: {
  rating: number;
  size?: string;
}) {
  return (
    <div>
      <div className="flex gap-2">
        {[...Array(rating > 5 ? 5 : rating)].map((_, i) => (
          <img
            key={i}
            className={size || 'w-[18px] h-[18px]'}
            src="/images/interface-favorite-star--reward-rating-rate-social-star-media-favorite-like-stars.svg"
            alt=""
          />
        ))}
        {[...Array(Math.abs(5 - (rating > 5 ? 5 : rating)))].map((_, i) => (
          <img
            key={i}
            className={size || 'w-[18px] h-[18px]'}
            src="/images/interface-favorite-star--reward-rating-rate-social-star-media-favorite-like-stars (1).svg"
            alt=""
          />
        ))}
      </div>
    </div>
  );
}
