import React from "react";

// HELPERS
import { copyTextToClipboard } from "../../helpers/utils";

export interface PopupSubscribeProps {
	closeButton: React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	>;
}

export default function PopupSubscribe({
	closeButton,
	...rest
}: PopupSubscribeProps) {
	// STATES
	const [copied, setCopied] = React.useState(false);

	return (
		<div className="fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-70 md:z-50 flex items-center justify-center  md:p-10 z-[1020]">
			<div className="md:max-w-[940px] h-full md:h-auto flex items-center justify-center text-center w-full relative px-10 py-8 md:py-[72px] bg-primary md:rounded-[22px]">
				<img
					src="/images/Frame 2387.png"
					className="absolute hidden md:block top-0 right-0 h-full w-full bottom-0 left-0 z-0 rounded-[22px]"
					alt=""
				/>
				<img
					src="/images/Menu.png"
					className="absolute md:hidden top-0 right-0 bottom-0 h-full w-full left-0 z-0 rounded-[22px]"
					alt=""
				/>
				<button type="button" {...closeButton}>
					<img
						className="absolute z-20 top-10 left-10 w-10 h-10"
						src="/images/Group 14530 (1).svg"
						alt=""
					/>
				</button>
				<div className="max-w-[570px] relative z-10 ">
					<h3 className="font-damion mt-7 text-18xl text-white mb-4">
						<span>Schedule a Call</span>
					</h3>
					<p className="text-white text-6xl mb-8">
						Let Our Team of Professional amazon Listing Experts Take Care of All
						the Hard Work for You!
					</p>

					<div className="md:flex">
						<a
							className="flex-grow text-8xl text-white text-left p-2 rounded-tl rounded-bl bg-white bg-opacity-10"
							href="https://calendly.com/sales-list-it-on-amazon/15min"
							target="blank_"
						>
							15 Minute Meeting - List It
						</a>
						<button
							className={`bg-black ${
								copied ? "bg-opacity-90" : "bg-opacity-25"
							} rounded-tr rounded-br p-2 hover:bg-opacity-90`}
							onClick={() => {
								setCopied(true);

								copyTextToClipboard(
									"https://calendly.com/sales-list-it-on-amazon/15min"
								);
								setTimeout(() => {
									setCopied(false);
								}, 2000);
							}}
						>
							<img
								src={copied ? "/images/check.svg" : "/images/file_copy.svg"}
								className=""
								height={22}
								width={22}
								title="Copy link"
								alt=""
							/>
						</button>
					</div>

					{/*<div className="hidden md:flex items-center">
						<input
							type="email"
							className="form-control flex-grow translate-x-[1px]"
							placeholder="Your email address"
							style={{ borderRadius: "8px 0 0 8px" }}
						/>
						<button
							type="button"
							className="black-button rounded-right hidden md:block"
							style={{ borderRadius: "0px 8px 8px 0px" }}
						>
							Request Callback
						</button>
					</div>
					 <div className="flex md:hidden md:flex-row gap-2 flex-col">
						<input
							type="email"
							className="form-control flex-grow translate-x-[1px] text-center"
							placeholder="Your email address"
						/>
						<button type="button" className="black-button">
							Request Callback
						</button>
					</div> */}
				</div>
			</div>
		</div>
	);
}
