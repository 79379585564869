import React from 'react';

export interface SectionTitleProps {
  title: string;
  subtitle: string;
  subtitleColor?: string;
  direction?: string;
  children: JSX.Element;
  color: 'blue' | 'primary';
  lastChildren?: boolean;
  end?: boolean;
}

export default function SectionTitle(props: SectionTitleProps) {
  const { title, subtitle, end, lastChildren, subtitleColor, children, color, direction } = props;

  let colorStyle = 'bg-linear-blue';
  if (color === 'blue') {
    colorStyle = 'bg-linear-blue';
  } else if (color === 'primary') {
    colorStyle = 'bg-linear-orange';
  }

  return (
    <div className="flex container md:justify-center mb-12 md:mb-20 text-left md:text-center">
      <div>
        <div className={`flex  mb-3 ${direction || 'flex-col'}`}>
          <div className={`${end ? 'self-end' : ''} ${lastChildren ? 'hidden md:block' : ''}`}>
            {children}
          </div>
          <h2
            className={`${colorStyle} linear-text text-17xl md:text-18xl font-bold relative z-10`}
          >
            {title}
          </h2>
          {lastChildren && <div className={`h-0 md:hidden`}>{children}</div>}
        </div>
        <p className={`text-5xl ${subtitleColor || 'text-black'} text-opacity-80`}>{subtitle}</p>
      </div>
    </div>
  );
}
