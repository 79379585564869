import { useState } from "react";
import useNavbarEffect from "../../../hooks/useNavbarEffect";
import Logo from "./Logo";
import Pages from "./Pages";
import "react-modern-drawer/dist/index.css";

interface NavbarProps {
	toggleSub: () => void;
}

export default function Navbar({ toggleSub }: NavbarProps) {
	const navbar = useNavbarEffect("py-[18px]", "py-2.5", 100);
	const menus = useNavbarEffect("top-[91px]", "top-[75px]", 100);
	const [drawer, setDrawer] = useState<boolean>(false);
	const toggleDrawer = () => setDrawer((v) => !v);

	return (
		<div className="sticky top-0 left-0 right-0 z-[1020]">
			<nav className="bg-black">
				<div className="container">
					<div
						className={`flex justify-between duration-150 items-center ${navbar}`}
					>
						<Logo />
						<div className="hidden md:block">
							<Pages />
						</div>
						<div className="hidden md:block">
							<button
								type="button"
								className="primary-button"
								onClick={toggleSub}
							>
								schedule a call
							</button>
						</div>
						<div className="md:hidden">
							<button type="button" onClick={toggleDrawer}>
								<img
									src="/images/Group 14568 (1).svg"
									alt=""
									className="w-7 h-3.5"
								/>
							</button>
						</div>
					</div>
				</div>
			</nav>
			{drawer && (
				<div className="">
					<div
						className={`bg-white duration-150 fixed ${menus} left-0 right-0 bottom-0`}
					>
						<div className="flex flex-col justify-between h-full gap-10">
							<div className="px-[26px] py-10 flex flex-col gap-8 items-start">
								<Pages black />
							</div>
							<div>
								<div className="px-[26px] pb-10 flex flex-col gap-5 text-center">
									<div className="flex flex-col w-full">
										<button
											type="button"
											className="primary-button text-center font-normal"
											onClick={toggleSub}
										>
											schedule a call
										</button>
									</div>
									<a
										href="mailto:sales@listitamazon.com"
										className="text-black text-xl"
									>
										sales@listitamazon.com
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
