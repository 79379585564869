import { useEffect } from "react";

function useScrollReset() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return null;
}

export default useScrollReset;
