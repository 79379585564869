import React from 'react';

export default function Quote() {
  return (
    <div className="container">
      <div className="relative">
        <img
          src="/images/ceo-picture-quote-Desktop.svg"
          className="hidden lg:block w-full"
          alt=""
        />
        <img
          src="/images/ceo-picture-quote.svg"
          className="w-full block lg:hidden"
          alt=""
        />
        <div className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
          <div className="lg:ml-[40px] py-20 px-10 lg:px-0 w-full max-w-[460px]">
            <h3 className="text-12xl lg:text-15xl text-white flex lg:flex-row flex-col items-center gap-6 font-bold lg:items-start lg:text-left text-center">
              <img className="w-8" src="/images/quote 3.svg" alt="" />
              <span>
                List It will do everything to make your product sell best!
              </span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
