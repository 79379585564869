import React from 'react';

export interface ItemProps {
  image: string;
  title: string;
  describe: string;
}

export default function Item({ image, title, describe }: ItemProps) {
  return (
    <li className="flex flex-col md:items-center md:text-center">
      <img className="w-12 mb-[18px]" src={image} alt={title} />
      <h4 className="font-bold text-8xl mb-[14px] text-black">{title}</h4>
      <p className="text-black text-opacity-80 text-2xl">{describe}</p>
    </li>
  );
}
