import React from 'react';

export interface SendButtonProps {
  text: string;
  icon: string;
  color: 'primary' | 'green' | 'whatsapp';
  iconSize: number;
  onClick?: () => any;
}

export default function SendButton({
  text,
  icon,
  color,
  iconSize,
  onClick,
}: SendButtonProps) {
  let styleColor = '';
  if (color === 'primary') {
    styleColor = 'primary-button';
  } else if (color === 'green') {
    styleColor = 'green-button';
  } else if (color === 'whatsapp') {
    styleColor = 'whatsapp-button';
  }

  return (
    <div>
      <button
        onClick={onClick}
        type="submit"
        className={`gap-2.5 ${styleColor} w-full flex justify-center items-center`}
      >
        <span>{text}</span>
        <img src={icon} alt="" width={iconSize} height={iconSize} />
      </button>
    </div>
  );
}
