import React from 'react';
import SectionTitle from '../../../components/global/SectionTitle';
import Item from './Item';
import lists from './lists.json';

export default function WeDo() {
  return (
    <section className="md:mb-36 mb-16">
      <SectionTitle
        color="primary"
        title="What we do best?"
        subtitle="Here’s a quick overview of the amazing services we have to offer you"
        lastChildren
        end
      >
        <img
          className="w-[107px] translate-x-48 -translate-y-20 self-end md:-translate-x-20 md:translate-y-8"
          src="/images/Group.svg"
          alt=""
        />
      </SectionTitle>

      <ul className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 list-none md:gap-10 gap-12">
        {lists.map(({ id, ...rest }) => (
          <Item key={id} {...rest} />
        ))}
      </ul>
    </section>
  );
}
