import React from 'react';
import Rating from '../../../components/common/Rating';

export interface ItemProps {
  image: string;
  name: string;
  title: string;
  describe: string;
  rating: number;
}

export default function Item({ image, name, title, describe, rating }: ItemProps) {
  return (
    <div className="flex gap-5 border border-[#FF6B00] bg-[#FFF8F1] rounded-[16px] p-8">
      <div className="">
        <div className="w-10">
          <img className="w-full rounded-full" src={image} alt={name} />
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <p className="text-2xl">“{describe}”</p>
        <div className="flex justify-between items-center ">
          <div className="flex flex-col gap-1">
            <strong className="text-lg">{name}</strong>
            <p className="text-black text-opacity-80">{title}</p>
          </div>
          <div>
            <Rating size="w-4 h-4" rating={rating} />
          </div>
        </div>
      </div>
    </div>
  );
}
