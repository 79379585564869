import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import pages from './pages.json';

export default function Pages({ black }: { black?: boolean }) {
  const location = useLocation();
  const path = location.pathname;

  return (
    <ul className="list-none w-full flex md:flex-row flex-col gap-[22px] md:gap-10">
      {pages.map(({ id, name, url }, index) => {
        const current = index ? path.indexOf(url) >= 0 : path === url;

        return (
          <React.Fragment key={id}>
            <li>
              <Link
                className={`${
                  current ? 'text-primary' : black ? 'text-black' : 'text-white'
                } font-aton text-9-5xl lg:text-md uppercase`}
                to={url}
              >
                {name}
              </Link>
            </li>
            <li className="w-full h-[1px] bg-gray-300 md:hidden last:hidden" />
          </React.Fragment>
        );
      })}
    </ul>
  );
}
