import React, { useState } from 'react';
import reviews from './reviews.json';
import SectionTitle from '../../../components/global/SectionTitle';
import Item from './Item';

export default function Reviews() {
  const [expand, setExpand] = useState<boolean>(false);
  const toggleExpand = () => setExpand((v) => !v);

  return (
    <div className="md:py-[150px] py-16 bg-black mb-[86px] md:mb-[150px]">
      <SectionTitle
        color="primary"
        title="Loved by Amazon people"
        subtitleColor="text-white"
        subtitle="Here’s a what List It clients are saying"
      >
        <img
          className="w-[51px] translate-y-8 translate-x-64 md:translate-y-[39px] md:translate-x-[379px] relative z-20"
          src="/images/61efe353f75daa49eb132367_Group 101 2.svg"
          alt=""
        />
      </SectionTitle>
      <div className="container">
        <div
          className={`relative duration-500 md:max-h-[none] ${
            expand ? 'max-h-[2000px]' : 'max-h-[800px]'
          } overflow-hidden`}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {reviews.map(({ id, ...rest }) => (
              <Item key={id} {...rest} />
            ))}
          </div>
          <div
            className={`${
              !expand ? 'absolute' : 'mt-20'
            } h-1/2 bg-review-hidden bottom-0 left-0 right-0 items-end justify-center flex md:hidden`}
          >
            <button
              type="button"
              className="font-aton text-md uppercase text-white flex items-center justify-center gap-2 -translate-y-6"
              onClick={toggleExpand}
            >
              <span>{expand ? 'Show Less' : 'Show more'}</span>
              <img
                src="/images/chevron-rightd.svg"
                className={`duration-150 w-4 h-4 ${expand ? 'rotate-180' : ''}`}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
