import SendButton from "../../../components/common/SendButton";

export default function AmazonBusinesses() {
	return (
		<section className="container relative overflow-hidden pt-[86px] md:pt-[200px] md:pb-[50px]">
			<img
				className="absolute hidden md:block z-0 top-0 -translate-y-[15%]"
				src="/images/bg5345345.svg"
				alt=""
			/>
			<img
				className="absolute md:hidden z-0 top-0 -translate-y-[15%]"
				src="/images/Group 48095947.svg"
				alt=""
			/>
			<div className="md:text-center relative z-10 flex md:justify-center flex-col gap-4">
				<h4 className="text-16xl font-bold">
					Trusted by thousands of{" "}
					<img className="inline -mb-3" src="/images/Vector (1).svg" alt="" />{" "}
					businesses
				</h4>
				<p className="gap-3 text-black text-opacity-80 text-6xl md:px-10 md:max-w-[360px] mx-auto mb-1.5">
					Let us help you sell your products. Dream it, sell it with List It!
				</p>
				<div className="md:max-w-max md:mx-auto">
					<SendButton
						onClick={() => {
							window.open("https://wa.me/message/7EQ2KROIL7TYO1");
						}}
						color="whatsapp"
						text="Send message via Whatsapp"
						icon="/images/whatsapp-brands.svg"
						iconSize={22}
					/>
				</div>
			</div>
		</section>
	);
}
