import React, { useState } from 'react';
import Footer from '../../components/layout/footer/Footer';
import Navbar from '../../components/layout/navbar/Navbar';
import useScrollReset from '../../hooks/useScrollReset';
import ContactSection from '../../components/global/ContactSection';
import Header from './Header';
import MeetListIt from './meet-list-it/MeetListIt';
import OurServies from './our-servies/OurServies';
import Testimonial from './testimonial/Testimonial';
import WeDo from './we-do/WeDo';
import AmazonBusinesses from './amazon-businesses/AmazonBusinesses';
import PopupSubscribe from '../../components/global/PopupSubscribe';

export default function Home() {
  useScrollReset();
  const [sub, setSub] = useState<boolean>(false);
  const toggleSub = () => setSub((v) => !v);

  return (
    <div>
      <Navbar toggleSub={toggleSub} />
      <Header toggleSub={toggleSub} />
      <AmazonBusinesses />
      <MeetListIt />
      <OurServies />
      <WeDo />
      <Testimonial />
      <ContactSection toggleSub={toggleSub} />
      <Footer toggleSub={toggleSub} />

      {sub && <PopupSubscribe closeButton={{ onClick: toggleSub }} />}
    </div>
  );
}
